export const users = [
    {
        "firstName": "Juan Carlos",
        "lastName": "Ortiz",
        "rols": [
            "piloto"
        ]
    },
    {
        "firstName": "Camilo",
        "lastName": "Perdomo",
        "rols": [
            "cliente"
        ]
    }
]