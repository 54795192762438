<div appTheme class="container">
  <!-- As a link -->
  <nav appTheme class="navbar">
    <a  appTheme class="navbar-brand" href="#">Space app</a>
  </nav>
  <div class="row mt-1">
    <div class="col-sm-2">
      <custom-menu></custom-menu>      
    </div>
    <div class="col-sm-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>